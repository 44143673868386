<template>
  <div v-if="this.currentActivity.targets === undefined && !reseted" class="info-container">
      <p class="text-infos">targets Differents suivant les activités</p>
      <RewriteButton :on-click="resetAllTargets"/>
  </div>

  <div v-else>
    <!-- Harvests -->
    <hr/>
    <h4 class="subtitle-form">Récoltes</h4>

    <!-- Harvests table -->
    <table>
      <tbody>
        <tr v-for="harvest in allHarvestsToPatch()" v-bind:key="harvest.id">
          <td class="text-infos shape-first-cell bg-white">
            <!-- Warehouse name -->
            <div class="warehouse-name-params">
              {{ getWarehouseName(harvest.warehouse) }}
            </div>

            <!-- Error message if quantity is wrong -->
            <div v-if="harvest.error" class="error-params">
              Quantité incorrecte
            </div>

            <!-- Inputs description -->
            <div>
              <span class="span-params"> Heure </span>
              <span class="span-params"> Quantité </span>
              <span class="span-params"> Unité </span>
            </div>

            <!-- Inputs hour, quantity and units select list -->
            <div class="margin-table-div">
              <input class="width-transport-inputs" v-model="harvest.tmpHour" type="time" />
              <NumericInput
                inputRef="input"
                :value="harvest.quantity"
                @input="i => {harvest.quantity = i}"
                class="width-transport-inputs"
                placeholder="Quantité"
              />
              <select class="width-transport-inputs" v-model="harvest.unit" @change="addHarvest()">
                <option v-for="unit in units" v-bind:key="unit.id" :value="unit.id">{{ unit.name }}</option>
              </select>
            </div>
          </td>

          <!-- Delete button -->
          <td>
            <button class="btn align-bottom" @click="onDelete(harvest.id)">
              <img :src="icon" alt="icon delete action" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Warehouse select list -->
    <select class="shape-select" v-model="warehouseSelected" @change="addHarvest()">
      <option value="" selected>---------</option>
      <option v-for="warehouse in this.warehouses" v-bind:key="warehouse.id" :value="warehouse.id">{{
        warehouse.name
      }}</option>
    </select>

    <!-- Confirmation modal to delete harvest -->
    <delHarvest ref="deleteHarvest" :deleteFunction="deleteHarv"></delHarvest>
  </div>
</template>

<script>
// import delButton from "@/components/del-button.vue";
import delIcon from "@/assets/images/delete.svg";
import HarvestModule from "@/store/modules/harvestsStorage";
import ActivityModule from "@/store/modules/activity";
// import { INIT_NEW_ACTIVITY } from "@/store/modules/activity";
import WarehouseModule from "@/store/modules/warehouse";
import { mapGetters } from "vuex";
import { CLEAN_HARVEST } from "@/store/modules/harvestsStorage";
import delHarvest from "@/components/activity/modals/DelHarvest.vue";
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import LoadFormPartMixin from "@/mixins/LoadFormPartMixin";
import {formatDate} from "@/utils/commons";
import RewriteButton from "@/components/commons/RewriteButton.vue";
import NumericInput from "@/components/commons/NumericInput.vue";


export default {
  mixins: [DetailUpdateMixin, LoadFormPartMixin],  
  name: "edit-transport",
  props: {
    useForm: {
      type: String,
      require: true,
    },
  },
  created: function() {
    // Si on modifie une activité on initialise le tableau des récoltes
    // à partir de l'activité actuel
    this.$store.commit(CLEAN_HARVEST, false);

    if (this.$route.name === "activity.edit") {
      this.harvests.map(harvest => {
        this.harvestsToPatch.push({
          id: harvest.id,
          tmpId: false,
          doneDate: harvest.doneDate,
          quantity: harvest.quantity,
          unit: harvest.unit,
          activity: harvest.activity,
          warehouse: harvest.warehouse,
          tmpHour: formatDate(harvest.doneDate, "time"),
          error: false,
        });
      });
    }
    console.log("EditTransport");
  },
  data() {
    return {
      page: this.useForm,
      icon: delIcon,
      warehouseSelected: "",
      toSend: true,
      harvestsToPatch: [],
      greatestId: 0,
      harvestToDel: 0,
      units: [
        {
          id: "kg",
          name: "kg",
        },
        {
          id: "q",
          name: "quintaux",
        },
        {
          id: "T",
          name: "tonnes",
        },
        {
          id: "b",
          name: "ballots",
        },
      ],
      loadComponent: false,
      reseted: false,
    };
  },
  components: {
    delHarvest,
    RewriteButton,
    NumericInput
  },
  methods: {
    /**
     * Description: Sauvegarde les récoltes et vérifie que les quantités sont correcte.
     * @return {Boolean}  Retourne true si la sauvegarde s'est bien déroulé
     *                    sinon false et affiche l'erreur survenue.
     */
    async saveHarvestsToPatch() {
      this.checkQuantities();

      if (this.toSend) {
        for (let i = 0; i < this.harvestsToPatch.length; i++) {
          if (this.$route.name === "activity.edit")
            this.harvestsToPatch[i].doneDate = new Date(
              this.harvestsToPatch[i].doneDate.slice(0, 10) + "," + this.harvestsToPatch[i].tmpHour,
            );
          else {
            this.harvestsToPatch[i].doneDate = new Date(
              this.newActivity.startDate.slice(0, 10) + "," + this.harvestsToPatch[i].tmpHour,
            );
            this.harvestsToPatch[i].activity = this.newActivity.id;
          }
          if (this.harvestsToPatch[i].tmpId) {
            // Si l'harvest n'existe pas, on POST
            delete this.harvestsToPatch[i].tmpHour;
            delete this.harvestsToPatch[i].id;
            delete this.harvestsToPatch[i].tmpId;
            delete this.harvestsToPatch[i].error;

            await this.$store.dispatch("harvestStorage/createHarvest", {
              data: this.harvestsToPatch[i],
            });
          } else {
            // Sinon on UPDATE
            let id = this.harvestsToPatch[i].id;

            delete this.harvestsToPatch[i].tmpHour;
            delete this.harvestsToPatch[i].id;
            delete this.harvestsToPatch[i].tmpId;
            delete this.harvestsToPatch[i].error;

            await this.$store.dispatch("harvestStorage/updateHarvest", {
              data: this.harvestsToPatch[i],
              id: id,
            });
          }
        }

        return true;
      } else return false;
    },

    /**
     * Vérifie si les quantités saisies sont coorecte.
     */
    checkQuantities() {
      this.toSend = true;

      for (let i = 0; i < this.harvestsToPatch.length; i++) {
        if (this.harvestsToPatch[i].quantity > 0 && this.harvestsToPatch[i].quantity <= 2147483647)
          this.harvestsToPatch[i].error = false;
        else {
          this.toSend = false;
          this.harvestsToPatch[i].error = true;
        }
      }

      return this.toSend;
    },

    /**
     * Open modal to delete harvest into harvestsToPatch.
     */
    onDelete(id) {
      this.harvestToDel = id;
      if (!this.getHarvestAttribut(this.harvestToDel, "tmpId", "id")) this.$refs.deleteHarvest.modalDeleteHarvest();
      else this.deleteHarvestIntoHtp(this.harvestToDel);
    },

    /**
     * Delete harvest into harvestsToPatch.
     */
    deleteHarvestIntoHtp(idHarvest) {
      let val = this.harvestsToPatch.filter(function(elem) {
        if (elem.id == idHarvest) return elem;
      });

      const filter = this.harvestsToPatch.indexOf(val[0]);
      if (filter > -1) this.harvestsToPatch.splice(filter, 1);
    },

    /**
     * Request delete harvest.
     */
    async deleteHarv() {
      let idHarvest = this.harvestToDel;

      await this.$store.dispatch("harvestStorage/deleteHarvest", {
        id: idHarvest,
      });

      this.deleteHarvestIntoHtp(idHarvest);

      this.$refs.deleteHarvest.onClose();
    },

    /**
     * Set la variable greatestId au plus grand id + 1.
     */
    getGreatestHarvestsId() {
      let idArray = [];

      this.harvestsToPatch.map(harvest => {
        idArray.push(harvest.id);
      });

      this.greatestId = Math.max(...idArray) + 1;
    },

    /**
     * Add harvest into the array 'harvestsToPatch'.
     */
    addHarvest() {
      if (this.warehouseSelected != "") {
        if (this.harvestsToPatch.length === 0) this.greatestId = 1;
        else if (this.greatestId != 0) this.greatestId += 1;
        else this.getGreatestHarvestsId();

        this.harvestsToPatch.push({
          id: this.greatestId,
          tmpId: true,
          doneDate: this.$route.name === "activity.new" ? this.newActivity.startDate : this.currentEntry.endDate,
          quantity: "",
          unit: "kg",
          activity: this.$route.name === "activity.new" ? this.newActivity.id : this.currentEntry.id,
          warehouse: this.warehouseSelected,
          tmpHour: formatDate(
            this.$route.name === "activity.new" ? this.newActivity.startDate : this.currentEntry.endDate,
            "time",
          ),
          error: false,
        });

        this.warehouseSelected = "";
      }
    },

    /**
     * Retourne le nom de l'entrepôt par rapport à l'ID fourni en paramètre.
     * @param {Number}      id L'ID de l'entrepôt.
     * @return {String}     Le nom de l'entrepôt.
     */
    getWarehouseName(id) {
      let warehouse = this.warehouses.filter(function(elem) {
        if (elem.id == id) return elem;
      });

      return warehouse[0]["name"];
    },

    /**
     * Renvoie toutes les récoltes à patcher.
     * Vérifie si il y a eu un changement de type d'activité et s'il faut donc
     * vider le contenu du tableau 'harvestsToPatch'.
     */
    allHarvestsToPatch() {
      if (this.harvestsMustBeToClean) {
        this.harvestsToPatch = [];
        this.$store.commit(CLEAN_HARVEST, false);
      }

      return this.harvestsToPatch;
    },

    /**
     * Description: Renvoie l'attribut d'une récolte selon l'ID (par defaut) et le nom d'attribut renseigné.
     * @param {number}      attrFilter La valeur de l'attribut de la récolte que l'on connaît.
     * @param {String}      attr Le nom de l'attribut que l'on recherche.
     * @param {String}      cmp Le nom de l'attribut par lequel comparer la value de attrFilter (l'ID par défaut).
     * @return {}           La valeur de retour dépend de l'attribut recherché.
     */
    getHarvestAttribut(attrFilter, attr, cmp = "id") {
      let harvest = this.harvestsToPatch.filter(function(elem) {
        if (elem[cmp] == attrFilter) return elem;
      });

      return harvest[0][attr];
    },

    /**
     * Description: Confirme le chargement des données de ce composant.
     * @return {Boolean} Component loaded.
     */
    validateForm: function() {
      this.isValid = true;
      return this.loadComponent;
    },
    resetAllTargets(){
      this.reseted = true
      this.$store.dispatch(this.storeEditAction, {targets: [], targetsReseted: this.reseted});
    }
  },
  beforeCreate() {
    const store = this.$store;
    if (store && store.state) {
      if (!store.state["harvestsStorage"]) {
        store.registerModule("harvestsStorage", HarvestModule);
      }

      if (!store.state["activity"]) {
        store.registerModule("activity", ActivityModule);
      }

      if (!store.state["warehouse"]) {
        store.registerModule("warehouse", WarehouseModule);
      }
    }
  },
  computed: {
    ...mapGetters({ currentActivity: "activity/currentEntry", newActivity: "activity/newEntry" }),

    ...mapGetters(["harvestsMustBeToClean", "warehouses", "harvests"]),
  },
};
</script>

<style lang="scss" scoped>
table {
  border-collapse: separate;
  border-spacing: 3px 6px;
  margin-bottom: 4px;
}

button {
  //   background-color: $danger-color;
  font-size: 0.8em;
  font-weight: bold;

  img {
    width: 1.5em;
  }
}

/**
 * Width for the transport inputs .
 */
.width-transport-inputs {
  border-radius: 4px;
  width: 30%;
  margin-right: 5px;
  height: 25px;
}

/**
 * Margin for table div.
 */
.margin-table-div {
  margin-top: 4px;
}

/**
 * Parameter for the warehouse name.
 */
.warehouse-name-params {
  text-align: center;
  margin-bottom: 5px;
}

/**
 * Span params.
 */
.span-params {
  margin-left: 2px;
  margin-right: 50px;
  color: $text-time-color;
}

/**
 * Parameter error quantity.
 */
.error-params {
  text-align: center;
  color: $danger-color;
}
</style>
