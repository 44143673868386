<template>
    <button class="btn align-bottom" @click="onClick" v-b-tooltip.hover:title="$t('reset_btn')">
      <img :src="icon" alt="icon edit action" />
      {{ text }}
    </button>
  </template>

  <script>
  import gommeIcon from "@/assets/images/gomme.svg"

  export default {
    name: "rewriteButton",
    data() {
      return {
        icon: gommeIcon,
      };
    },
    props: {
      text: {
        type: String,
        require: true,
      },
      onClick: {
        type: Function,
        require: true,
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  button {
    background-color: $background-text-color;
    font-size: 0.8em;
    font-weight: bold;
    padding: 6px;

    img {
      width: 2.2em;
    }
  }

  button:hover {
    background: $secondary-light-color;
  }
  </style>
